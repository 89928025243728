import { toNumber } from 'lodash';
import AOS from 'aos';
import 'aos/dist/aos.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap';
import '../marketing/styles/sales-site-global.scss';

window.AOS = AOS;
window.$ = $;
window.jQuery = $;

async function overAnimation(currentIndex, itemsToCycle, transitionSpeed, waitPeriod) {
  return new Promise(((resolve) => {
    setTimeout(() => {
      let newIndex = currentIndex + 1;
      if (newIndex + 1 > itemsToCycle.length) newIndex = 0;
      $(itemsToCycle[newIndex]).fadeTo(transitionSpeed, 1, () => {
        $(itemsToCycle[currentIndex]).fadeTo(transitionSpeed, 0, () => {
          $(itemsToCycle[newIndex]).css('z-index', 2);
          $(itemsToCycle[currentIndex]).css('z-index', 1);
          resolve(newIndex);
        });
      });
    }, waitPeriod);
  }));
}

async function outInAnimation(currentIndex, itemsToCycle, transitionSpeed, waitPeriod) {
  return new Promise(((resolve) => {
    setTimeout(() => {
      let newIndex = currentIndex + 1;
      if (newIndex + 1 > itemsToCycle.length) newIndex = 0;
      $(itemsToCycle[currentIndex]).fadeTo(transitionSpeed, 0, () => {
        $(itemsToCycle[newIndex]).fadeTo(transitionSpeed, 1, () => {
          $(itemsToCycle[newIndex]).css('z-index', 2);
          $(itemsToCycle[currentIndex]).css('z-index', 1);
          resolve(newIndex);
        });
      });
    }, waitPeriod);
  }));
}

async function cycleItems(itemsToCycle, transitionSpeed, waitPeriod, animationFunction) {
  let currentIndex = 0;
  const currentNode = $(itemsToCycle[currentIndex]);
  itemsToCycle.css({ opacity: 0 });
  currentNode.css({ opacity: 1 });

  while (true) {
    // This is some bad practise but avoids JS's lack of recursive optimization
    // eslint-disable-next-line
    currentIndex = await animationFunction(currentIndex, itemsToCycle, transitionSpeed, waitPeriod);
  }
}

function supportsTouchEvents() {
  try { document.createEvent('TouchEvent'); return true; } catch (e) { return false; }
}

window.supportsTouchEvents = supportsTouchEvents();

const holdAspectRatio = () => {
  const aspectNodes = $('.hold-aspect-ratio-w');
  aspectNodes.each((idx) => {
    const node = $(aspectNodes[idx]);
    const ratio = toNumber(node.data('aspect-ratio') || 1);
    const width = node.width();
    node.height(width / ratio);
  });
};

$(document).ready(() => {
  setTimeout(holdAspectRatio, 10);
  $(window).resize(holdAspectRatio);


  const itemCycleNodes = $("[data-cycle-container='fade']");
  itemCycleNodes.each((idx) => {
    const node = $(itemCycleNodes[idx]);
    const transitionSpeed = toNumber(node.data('cycle-delay') || 400);
    const startDelay = toNumber(node.data('cycle-start-delay') || 0);
    const waitPeriod = toNumber(node.data('cycle-wait') || 5000);
    const transitionStyle = node.data('cycle-style') || 'over';
    const itemsToCycle = node.find("[data-cycle-item='true']");
    if (itemsToCycle.length < 1) return;

    let animationFunction = overAnimation;
    switch (transitionStyle) {
      case 'outin':
        animationFunction = outInAnimation;
        break;
      default:
        animationFunction = overAnimation;
    }

    setTimeout(() => {
      cycleItems(itemsToCycle, transitionSpeed, waitPeriod, animationFunction);
    }, startDelay);
  });

  AOS.init();
});
